// Mobile Menu
var $ = jQuery;
var clickEvent = 'ontouchstart' in document.documentElement ? 'touchstart' : 'click';

// basic mobile markup
var markup = '' +
    '<div id="mobile-menu">' +
        '<a class="toggle" href="#">Menu</a>' + 
        '<div class="overflow">' + 
            '<div class="primary">' +
                '<div class="main-menu">' + 
                    '<div class="search"></div>' + 
                    '<ul></ul>' + 
                    '<div class="button"></div>' + 
                '</div>' + 
                '<div class="user-menu">' + 
                    '<ul></ul>' + 
                '</div>' + 
            '</div>' +
            '<div class="secondary">' +
                '<div class="inner">' + 
                    '<a href="#" class="back">Back to menu</a>' + 
                    '<h4>Menu Title</h4>' + 
                    '<ul></ul>' + 
                '</div>' + 
            '</div>' + 
        '</div>' + 
    '</div>';



function MobileMenu() {
    this.blanker = null;
    this.menu = null
}

MobileMenu.prototype.init = function() {
    this.createMarkup();
    this.bindEvents();
};

MobileMenu.prototype.createMarkup = function() {
    this.blanker = $('<div id="mobile-blanker" />').appendTo($('body'));

    this.menu = $(markup);
    this.menu.appendTo($('body'));

    this.createMainMenu();

    this.createUserMenu();

};

MobileMenu.prototype.createMainMenu = function() {
    var $this = this;
    var search = this.menu.find('.main-menu .search');

    // add search box
    var search_form = $('#search-wrap form').clone().appendTo(search);
    // change id's to prevent clashes
    search_form.find('*[id]').each(function() { $(this).attr('id', $(this).attr('id') + '-mobile'); });
    // alter placeholder text
    search_form.find('input:text').attr('placeholder', 'Search RHD');
    // add better submit button
    $('<a id="mobile-search-submit" href="#" />').appendTo(search_form);

    // iterate existing main menu and create tree
    var ul = this.menu.find('.main-menu ul');
    var old_menu = $('#block-menu-block-rhd-blocks-custom-nav .menu-name-main-menu>ul');
    old_menu.find('>li').each(function() {
        if($(this).is(':last-child')) {
            // this link appears as a button below the main menu
            $(this).find('>a').clone().appendTo($this.menu.find('.main-menu .button'));
            return;
        }
        var li = $('<li />').appendTo(ul);
        $(this).find('>a').clone().appendTo(li)

        // sub menu
        var sub = $(this).find('.sub ul.menu');
        if(sub.length) {
            li.find('a').addClass('has-sub-menu');
            var ul2 = $('<ul />').appendTo(li);
            sub.find('>li>a').each(function() {
                var li2 = $('<li />').appendTo(ul2);
                $(this).clone().appendTo(li2);
            });
        }

    });
}

MobileMenu.prototype.createUserMenu = function() {
    var ul = this.menu.find('.user-menu ul');
    var old_menu = $('#secondary-menu>ul');
    old_menu.find('>li').each(function() {
        var li = $('<li />').appendTo(ul);
        $(this).find('>a').clone().appendTo(li)
    });
};

MobileMenu.prototype.bindEvents = function() {
    var $this = this;

    this.menu.on(clickEvent, '.toggle', function(e) {
        e.preventDefault();
        $('body').toggleClass('show-mobile-menu');
    });
    this.blanker.on(clickEvent, function(e){
       $('body').removeClass('show-mobile-menu'); 
    });

    this.menu.on(clickEvent, '.main-menu .has-sub-menu', function(e) {
        e.preventDefault();
        // populate menu
        $this.menu.find('.secondary h4').text($(this).text());
        var ul2 = $this.menu.find('.secondary ul');
        ul2.empty();
        $(this).closest('li').find('ul').find('li').each(function() {
            $(this).clone().appendTo(ul2);
        });

        $this.menu.addClass('show-secondary');
        return false;
    });

    this.menu.on(clickEvent, '.secondary .back', function(e) {
        e.preventDefault();
        $this.menu.removeClass('show-secondary');
        return false;
    });

    $('#mobile-search-submit').on(clickEvent, function(e) {
        e.preventDefault();
        $(this).closest('form').submit();
    });

};

module.exports = new MobileMenu();