var mobile_menu = require('./mobile_menu');

(function ($) {

  var clickEvent = 'ontouchstart' in document.documentElement ? 'touchstart' : 'click';

  $(function() {
    // main menu hover state
    var menu_timeout = null;
    var menu_delay = 300;
    var menu_showing = null;
    var menu = $('.menu-name-main-menu > ul');
    function showMenu() {
      console.log('showing menu');
      menu_showing.addClass('hover');
    }

    function hideMenu() {
      console.log('hiding menu');
      menu.find('>li').removeClass('hover');
      menu_showing = null;
      clearTimeout(menu_timeout);
    }

    console.log('ready');
    menu.find('>li').on('mouseenter', '>a, >.sub', function() {
      clearTimeout(menu_timeout);
      if(menu_showing) {
        // menu is already open, switch to the next one
        console.log('Switching menu to next option');
        menu_showing.removeClass('hover');
        menu_showing = $(this).closest('li');
        menu_showing.addClass('hover');
        return;
      }
      console.log('starting menu open delay');
      menu_showing = $(this).closest('li');
      menu_timeout = setTimeout(showMenu, menu_delay);
    });

    menu.find('>li').on('mouseleave', '>a, >.sub', function() {
      if(menu_timeout) clearTimeout(menu_timeout);
      if(menu_showing) {
        menu_timeout = setTimeout(hideMenu, 30);
      }
    });


    // crazy
    var credit_clone = $("#block-rhd-blocks-rhd-credit").clone();
    credit_clone.attr("id","cloned-credit");
    $("#block-views-tweets-block").append('<a href="https://www.facebook.com/RHDAction/" class="block__content footer-facebook"><img src="/sites/all/themes/rhd/img/facebook.svg"></a>');
    $("#block-views-tweets-block").append(credit_clone);

    // header search
    var search_elem = $("#block-search-api-page-default-search");
    var sub_elem = $("#block-search-api-page-default-search input[type=submit]");
    if(search_elem.length && sub_elem.length) {
      sub_elem.on(clickEvent, function(e){
        console.log("Search inside");
        if (!search_elem.hasClass("active")) {
          e.preventDefault();
          search_elem.addClass("active");
        }
      });
    }

    $(".menu-block-wrapper > ul.menu > li.active-trail").closest("ul.menu").addClass("active-trail");

    $(".quicklinks a, a.top, .to-top a").smoothScroll({
        offset: -50
    });

    $("a.news-year").on(clickEvent, function(e){
      e.preventDefault();
      $(this).closest("li").toggleClass("show");
    });

    $("#close-search").on(clickEvent, function(e){
      e.preventDefault();
      closeSearch();
    });

    $(document).on("keyup", function(e){
      if (e.keyCode == 27) {
        closeSearch();
      }
    });

    function closeSearch() {
      search_elem.removeClass("active");
    }

    // homepage tablet slideshow
    if($('.homepage-cta').length) {
      var curslide = -1;
      function showSlide(n) {
        if(n == curslide) return;
        $('.homepage-cta .cta').css('z-index', 10);
        $('.homepage-cta .cta').eq(n).hide();
        $('.homepage-cta .cta').eq(n).css('z-index', 20).fadeIn();
        $('.homepage-cta-nav li').removeClass('active');
        $('.homepage-cta-nav li').eq(n).addClass('active');
        curslide = n;
      }
      $('.homepage-cta .cta').each(function() {
        $('<li><a href="#"></a></li>').appendTo($('.homepage-cta-nav'));
      });
      $('.homepage-cta-nav').on('click', 'a', function(e) {
        e.preventDefault();
        var li = $(this).closest('li');
        showSlide(li.index());
      });
      showSlide(0);
    }

    // resource info toggle
    if (!$('body').hasClass("page-resource-hub-results")) {
      $('.resource').on(clickEvent, '.more-info, .less-info', function(e) {
        e.preventDefault();
        $(this).closest('.resource').toggleClass('expanded');
      });
    }

    // content page sidebar affix
    var sidebar_outer = $('.page-sidebar');
    var sidebar = sidebar_outer.find('.section-menu');
    var page_main = $('.page-main');
    var offset_top = 20;
    function moveSidebar() {

      var scroll_top = $(window).scrollTop() + offset_top;
      var outer_top = sidebar_outer.offset().top;
      var max_top = page_main.offset().top + page_main.outerHeight() - sidebar.outerHeight();
      if(scroll_top > max_top) {
        sidebar.css({
          position: 'absolute',
          top: max_top - outer_top
        });
      }
      else if(scroll_top > outer_top) {
        sidebar.css({
          position: 'fixed',
          top: offset_top
        });
      }
      else {
        sidebar.css({
          position: 'absolute',
          top: 0
        });
      }

      setTimeout(moveSidebar, 10);
    }

    if($('.page-sidebar .section-menu').length) {
      moveSidebar();
    }

    mobile_menu.init();

    $(".menu-block-rhd_blocks_main_nav > ul > li > a").on("click", function(e){
        var w = $(window).width();
        if(w > 768 || $(this).closest("li").hasClass('last')) return;

        e.preventDefault();
        e.stopPropagation();
        var li = $(this).closest("li");

        if(li.find("ul").length) {
          li.toggleClass("show");
        }
        return false;
    });

  });

}(jQuery));